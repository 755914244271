.order-summary {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.order-details,
.delivery,
.additional-services,
.payment-summary {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    width: 30%;
}

.order-item {
    display: flex;
    align-items: center;
}

.item-details {
    margin-left: 15px;
}

.original-price {
    text-decoration: line-through;
    color: gray;
}

.payment-method {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    width: 50%;
    margin: auto;
}

.payment-option {
    margin: 10px 0;
}