body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: aliceblue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.order-section,
.delivery-section,
.additional-services-section,
.payment-summary-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  flex-basis: 48%;
}

.order-item,
.delivery-option,
.service-option {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.order-item img {
  margin-right: 15px;
}

.price {
  display: flex;
  align-items: center;
}

.sale-price {
  color: red;
  margin-right: 10px;
}

.original-price {
  text-decoration: line-through;
  color: gray;
}


.payment-method-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  margin: auto;
  margin-top: 40px;
}

.payment-option {
  margin: 10px 0;
}